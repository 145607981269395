import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Card from '@x-functions/freyja/lib/components/card';
import Media from '@x-functions/freyja/lib/components/media';
import Button from '@x-functions/freyja/lib/components/button';
import Endpoint from './endpoint';
import styles from './baseNode.module.scss';

function BaseNode({
  id,
  className,
  inEndpoint,
  outEndpoint,
  onInEndpointClick,
  onOutEndpointClick,
  onNodeClick,
  onNodeRemoveClick,
  noRemove,
  readonly,
  image,
  body,
  selectedNode,
  selectedPath,
}) {
  const handleNodeClick = React.useCallback(() => {
    onNodeClick(id);
  }, [onNodeClick, id]);

  const handleNodeRemoveClick = React.useCallback(e => onNodeRemoveClick(id, e), [onNodeRemoveClick, id]);

  const inSelectionPath = React.useMemo(() => !!selectedPath.find(n => n.id === id), [selectedPath, id]);

  return (
    <div
      className={classNames(
        styles.root,
        className,
      )}
    >
      <Endpoint
        type={inEndpoint}
        inOut="in"
        onClick={onInEndpointClick}
        nodeId={id}
        readonly={readonly}
      />
      <Card
        className={classNames(styles.node, {
          [styles.selected]: id === selectedNode?.id,
          [styles.selectedPath]: inSelectionPath,
        })}
        onClick={handleNodeClick}
      >
        {noRemove || readonly ? null : (<Button remove className={styles.remove} onClick={handleNodeRemoveClick} />)}
        <Card.Content>
          <Media>
            <Media.Item renderAs="figure" position="left">
              {image}
            </Media.Item>
            <Media.Item>
              {body}
            </Media.Item>
          </Media>
        </Card.Content>
      </Card>
      <Endpoint
        type={outEndpoint}
        inOut="out"
        onClick={onOutEndpointClick}
        nodeId={id}
        readonly={readonly}
      />
    </div>
  );
}

BaseNode.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  inEndpoint: PropTypes.oneOf(['clickable', 'default', 'none']),
  outEndpoint: PropTypes.oneOf(['clickable', 'default', 'none']),
  onInEndpointClick: PropTypes.func.isRequired,
  onOutEndpointClick: PropTypes.func.isRequired,
  onNodeClick: PropTypes.func.isRequired,
  onNodeRemoveClick: PropTypes.func.isRequired,
  image: PropTypes.node,
  body: PropTypes.node,
  noRemove: PropTypes.bool,
  readonly: PropTypes.bool,
  selectedNode: PropTypes.shape({
    id: PropTypes.string,
  }),
  selectedPath: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })),
};

BaseNode.defaultProps = {
  className: undefined,
  inEndpoint: 'default',
  outEndpoint: 'clickable',
  image: null,
  body: null,
  noRemove: false,
  readonly: false,
  selectedNode: undefined,
  selectedPath: [],
};

export default BaseNode;
