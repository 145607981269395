import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@x-functions/freyja/lib/components/heading';
import Column from '../column';
import Level from '../level';
import Endpoint from './endpoint';
import BaseNode from './baseNode';
import Icon from './icon';
import { getConditionText } from '../conditions';
import styles from './conditional.module.scss';

const icon = <Icon icon="help" />;

function ConditionalNode({
  id,
  name,
  workflow,
  onNoMatch,
  choices,
  renderBranch,
  onOutEndpointClick,
  onFirstNodeEndpointClick,
  readonly,
  ...other
}) {
  return (
    <div className={styles.root}>
      <BaseNode
        id={id}
        readonly={readonly}
        {...other}
        className={styles.root}
        inEndpoint="default"
        outEndpoint="default"
        image={icon}
        body={(
          <Heading size={7}>{name}</Heading>
        )}
      />
      <Level nowrap>
        <Column key="noMatch" loop borderBottom className={styles.column}>
          <div className={styles.conditionLabel}><p>Default</p></div>
          {onNoMatch ? (
            renderBranch(onNoMatch, workflow)
          ) : (
            <Endpoint
              type="clickable"
              inOut="out"
              onClick={() => onFirstNodeEndpointClick(id, { conditionId: 'onNoMatch' })}
              nodeId={id}
              readonly={readonly}
            />
          )}
        </Column>
        {choices.map(choice => (
          <Column key={choice.id} loop borderBottom className={styles.column}>
            <div className={styles.conditionLabel}><p>{getConditionText(choice)}</p></div>
            {choice.onSuccess ? (
              renderBranch(choice.onSuccess, workflow)
            ) : (
              <Endpoint
                type="clickable"
                inOut="out"
                onClick={() => onFirstNodeEndpointClick(id, { conditionId: choice.id })}
                nodeId={id}
                readonly={readonly}
              />
            )}
          </Column>
        ))}
      </Level>
      <Endpoint
        className={styles.outEndpoint}
        type="clickable"
        inOut="out"
        onClick={onOutEndpointClick}
        nodeId={id}
        readonly={readonly}
      />
    </div>
  );
}

ConditionalNode.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onNoMatch: PropTypes.string,
  workflow: PropTypes.shape({}).isRequired,
  choices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    colour: PropTypes.string.isRequired,
    onSuccess: PropTypes.string,
    operator: PropTypes.string,
    condition: PropTypes.shape({
      comparison: PropTypes.string,
      dataType: PropTypes.string,
      value: PropTypes.string,
      variable: PropTypes.string,
    }),
  })).isRequired,
  renderBranch: PropTypes.func.isRequired,
  onOutEndpointClick: PropTypes.func,
  onFirstNodeEndpointClick: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
};

ConditionalNode.defaultProps = {
  onNoMatch: '',
  onOutEndpointClick: undefined,
  readonly: false,
};

export default ConditionalNode;
