import PropTypes from 'prop-types';

export const workflowNodeType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
  onSuccess: PropTypes.string.isRequired,
});

export const workflowType = PropTypes.shape({
  workflowId: PropTypes.string.isRequired,
  selectedNode: PropTypes.string,
  name: PropTypes.string,
  firstNode: PropTypes.string,
  nodes: PropTypes.objectOf(workflowNodeType),
});
