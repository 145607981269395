import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './level.module.scss';

function Level({ className, children, nowrap }) {
  return (
    <div
      className={classNames(
        styles.root,
        'is-flex is-flex-direction-row is-justify-content-center',
        { [styles.nowrap]: nowrap },
        className,
      )}
    >
      {children}
    </div>
  );
}

Level.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  nowrap: PropTypes.bool,
};

Level.defaultProps = {
  className: undefined,
  children: null,
  nowrap: false,
};

export default Level;
