import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@x-functions/freyja/lib/components/heading';
import Column from '../column';
import Level from '../level';
import BaseNode from './baseNode';
import Endpoint from './endpoint';
import Icon from './icon';
import styles from './loop.module.scss';

const icon = <Icon icon="repeat_on" />;

function LoopNode({
  id,
  name,
  firstNode,
  nodes,
  renderBranch,
  onOutEndpointClick,
  onFirstNodeEndpointClick,
  readonly,
  ...other
}) {
  return (
    <div className={styles.root}>
      <BaseNode
        id={id}
        readonly={readonly}
        {...other}
        outEndpoint="default"
        image={icon}
        body={(
          <Heading size={7}>{name}</Heading>
        )}
      />
      <Level>
        <Column key="loop" className={styles.loop}>
          {firstNode ? (
            renderBranch(firstNode, { nodes })
          ) : (
            <Endpoint
              type="clickable"
              inOut="out"
              onClick={() => onFirstNodeEndpointClick(id)}
              nodeId={id}
              readonly={readonly}
            />
          )}
        </Column>
      </Level>
      <Endpoint
        className={styles.loopEndpoint}
        type="clickable"
        inOut="out"
        onClick={onOutEndpointClick}
        nodeId={id}
        readonly={readonly}
      />
    </div>
  );
}

LoopNode.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  firstNode: PropTypes.string,
  nodes: PropTypes.shape({}).isRequired,
  renderBranch: PropTypes.func.isRequired,
  onOutEndpointClick: PropTypes.func,
  onFirstNodeEndpointClick: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
};

LoopNode.defaultProps = {
  firstNode: '',
  onOutEndpointClick: undefined,
  readonly: false,
};

export default LoopNode;
