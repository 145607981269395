import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@x-functions/freyja/lib/components/heading';
import BaseNode from './baseNode';
import Icon from './icon';

function FunctionNode({
  name,
  type,
  ...other
}) {
  let icon = 'code';
  switch (type) {
    case 'wait':
      icon = 'hourglass_empty';
      break;
    case 'approval':
      icon = 'approval';
      break;
    case 'wait-for-event':
      icon = 'flash_on';
      break;
    default:
      icon = 'code';
  }

  return (
    <BaseNode
      image={<Icon icon={icon} />}
      type={type}
      body={(
        <>
          <Heading size={7}>{name}</Heading>
          <Heading subtitle size={7}>
            {other.function}
          </Heading>
        </>
      )}
      {...other}
    />
  );
}

FunctionNode.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default FunctionNode;
