import React from 'react';
import PropTypes from 'prop-types';
import Node from './node';
import Level from './level';
import SingleBranch from './singleBranch';
import { workflowType } from './types';
import styles from './canvas.module.scss';

function Canvas({
  workflow,
  onNodeUpdated,
  ...other
}) {
  const renderBranch = React.useCallback((firstNodeId, wf, blockAddTop = false) => {
    if (!firstNodeId) return null;

    return (
      <SingleBranch
        workflow={wf || workflow}
        firstNodeId={firstNodeId}
        {...other}
        renderBranch={renderBranch}
        onNodeUpdated={onNodeUpdated}
        allowAddTop={!blockAddTop}
      />
    );
  }, [workflow, onNodeUpdated, other]);

  return (
    <div className={styles.root}>
      <Level>
        <Node
          key="event"
          {...workflow.nodes.event}
          updateNode={onNodeUpdated}
          {...other}
          noInEndpoint
          noRemove
        />
      </Level>
      {renderBranch(workflow.nodes.event.onSuccess, undefined, true)}
    </div>
  );
}

Canvas.propTypes = {
  workflow: workflowType,
  onNodeUpdated: PropTypes.func.isRequired,
};

Canvas.defaultProps = {
  workflow: undefined,
};

export default Canvas;
