export const comparisons = [
  { value: 'Equals', label: '=' },
  { value: 'GreaterThan', label: '>' },
  { value: 'GreaterThanEquals', label: '≥' },
  { value: 'LessThan', label: '<' },
  { value: 'LessThanEquals', label: '≤' },
];

export const getComparisonLabel = comparison => (comparisons.find(c => c.value === comparison) || {}).label || '';

export const dataTypes = [
  { value: 'String', label: 'Text' },
  { value: 'Numeric', label: 'Number' },
  { value: 'Timestamp', label: 'Date/Time' },
  { value: 'Boolean', label: 'Boolean' },
];

export const getConditionsForDataType = dataType => {
  if (dataType === 'Boolean') return comparisons.slice(0, 1);

  return comparisons;
};

export const shortVariableName = name => {
  const pieces = name?.split('.') || [];
  return pieces[pieces.length - 1] || '';
};

export const getConditionText = choice => {
  const { operator, condition: { variable, comparison, value } } = choice;
  if (!variable) {
    return 'Not configured';
  }
  return `${shortVariableName(variable)} ${operator === 'Not' ? '!' : ''}${getComparisonLabel(comparison)} ${value}`;
};
