import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@x-functions/freyja/lib/components/icon';
import styles from './icon.module.scss';

function NodeIcon({ icon }) {
  return (
    <Icon
      size="large"
      className={classNames('has-background-dark has-text-white', styles.icon)}
      rounded
    >
      <i className="material-icons md-24">{icon}</i>
    </Icon>
  );
}

NodeIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default NodeIcon;
