import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './column.module.scss';

function Column({
  className,
  children,
  borderBottom,
  loop,
}) {
  return (
    <div
      className={classNames(
        styles.column,
        'is-flex is-flex-direction-column',
        { [styles.borderBottom]: borderBottom, [styles.loop]: loop },
        className,
      )}
    >
      {children}
    </div>
  );
}

Column.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  borderBottom: PropTypes.bool,
  loop: PropTypes.bool,
};

Column.defaultProps = {
  children: null,
  className: undefined,
  borderBottom: false,
  loop: false,
};

export default Column;
