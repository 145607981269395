import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@x-functions/freyja/lib/components/heading';
import BaseNode from './baseNode';
import Icon from './icon';

const capitalise = text => {
  const [first, ...rest] = text?.split('') || [];
  return `${first?.toUpperCase() || ''}${rest?.join('')?.toLowerCase() || ''}`;
};

const icon = <Icon icon="flash_on" />;

function Trigger({
  type,
  ...other
}) {
  return (
    <BaseNode
      {...other}
      inEndpoint="none"
      image={icon}
      body={(
        <>
          <Heading size={7}>Workflow Trigger</Heading>
          <Heading subtitle size={7}>
            {capitalise(type || 'Not selected')}
          </Heading>
        </>
      )}
    />
  );
}

Trigger.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Trigger;
