import React from 'react';
import PropTypes from 'prop-types';
import { workflowType } from './types';
import Level from './level';
import Node from './node';

const getLevels = (workflow, firstNodeId) => {
  if (!firstNodeId) return [];
  if (!workflow.nodes[firstNodeId]) return [];

  const levels = [];
  let currentId = firstNodeId;
  while (currentId) {
    const node = workflow.nodes[currentId];
    levels.push(node);
    currentId = node.onSuccess;
  }

  return levels;
};

function SingleBranch({
  workflow,
  firstNodeId,
  allowAddTop,
  ...other
}) {
  const levels = React.useMemo(() => getLevels(workflow, firstNodeId), [workflow, firstNodeId]);

  return (
    <>
      {levels.map((level, i) => (
        <Level key={level.id}>
          <Node
            workflow={workflow}
            {...level}
            {...other}
            inEndpoint={allowAddTop && i === 0 ? 'clickable' : 'default'}
          />
        </Level>
      ))}
    </>
  );
}

SingleBranch.propTypes = {
  workflow: workflowType,
  firstNodeId: PropTypes.string.isRequired,
  allowAddTop: PropTypes.bool,
};

SingleBranch.defaultProps = {
  workflow: {},
  allowAddTop: false,
};

export default SingleBranch;
