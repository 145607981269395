export const baseColours = ['#35383C', '#0045F5', '#FFA61E', '#FF5500', '#7D00FE', '#00AA47', '#62DAD8'];

export const lightenColour = (colour, percentage) => {
  const finalPercentage = 1 + percentage; // if we want it 40% lighter (0.4), we multiply the colour by 1.4
  const newColour = colour
    // Remove the '#' sign
    .slice(1)
    // Separate into 3 groups of 2 characters (e.g. 0045F5 turns into '00', '45', 'F5')
    .match(/.{1,2}/g)
    // Turn each of the groups into an integer, apply the percentage and turn it back into a hexadecimal string
    .map(s => {
      const number = Math.min(Math.floor(parseInt(s, 16) * finalPercentage), 255);
      return `0${number.toString(16)}`.slice(-2); // ensure 2 digits (e.g. '1' becomes '01')
    })
    // join the 3 pieces again ('rrggbb')
    .join('');

  // Now include the hash sign again
  return `#${newColour}`;
};

export const getColour = branchIndex => {
  // Get the colour in the array
  // (e.g. 4th branch gets index 3, so does the 8th branch)
  let colour = baseColours[branchIndex % baseColours.length];

  // Lighten the colour by 20% every time it wraps around the array
  // (e.g. 4th branch gets the colour as is, 8th branch gets the same colour 40% lighter)
  const correctionPercentage = Math.floor(branchIndex / baseColours.length) * 0.8;
  if (correctionPercentage !== 0) {
    colour = lightenColour(colour, correctionPercentage);
  }

  return colour;
};
