import React from 'react';
import PropTypes from 'prop-types';
import TriggerNode from './trigger';
import FunctionNode from './function';
import ConditionalNode from './conditional';
import LoopNode from './loop';
import ParallelNode from './parallel';
import EndNode from './end';

function Node({
  type,
  ...other
}) {
  let Element;
  switch (type) {
    case 'event':
      Element = TriggerNode;
      break;
    case 'conditional':
      Element = ConditionalNode;
      break;
    case 'repeat':
    case 'map':
      Element = LoopNode;
      break;
    case 'parallel':
      Element = ParallelNode;
      break;
    case 'end':
      Element = EndNode;
      break;
    default:
      Element = FunctionNode;
  }

  return <Element type={type} {...other} />;
}

Node.propTypes = {
  type: PropTypes.string.isRequired,
};

Node.defaultProps = {};

export default Node;
