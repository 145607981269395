import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@x-functions/freyja/lib/components/heading';
import BaseNode from './baseNode';
import Endpoint from './endpoint';
import Icon from './icon';
import styles from './end.module.scss';

const icon = <Icon icon="stop" />;

function LoopNode({
  id,
  name,
  readonly,
  ...other
}) {
  return (
    <div className={styles.root}>
      <BaseNode
        id={id}
        readonly={readonly}
        {...other}
        outEndpoint="default"
        image={icon}
        body={(
          <Heading size={7}>{name}</Heading>
        )}
      />
      <Endpoint
        type="clickable"
        inOut="out"
        onClick={() => true}
        nodeId={id}
        readonly
      />
    </div>
  );
}

LoopNode.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
};

LoopNode.defaultProps = {
  readonly: false,
};

export default LoopNode;
