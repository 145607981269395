import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Button from '@x-functions/freyja/lib/components/button';
import Icon from '@x-functions/freyja/lib/components/icon';
import styles from './endpoint.module.scss';

function Endpoint({
  className,
  nodeId,
  onClick,
  inOut,
  type,
  readonly,
}) {
  const handleClick = React.useCallback(e => {
    onClick(nodeId, e);
  }, [nodeId, onClick]);

  if (type === 'none') return null;

  if (type === 'clickable') {
    return (
      <Button
        className={classNames(styles.connector, styles[inOut], className)}
        onClick={handleClick}
        color="dark"
        disabled={readonly}
      >
        <Icon><i className="material-icons md-16">add</i></Icon>
      </Button>
    );
  }

  return <div className={classNames(styles.connect, styles[inOut], className)} />;
}

Endpoint.propTypes = {
  nodeId: PropTypes.string.isRequired,
  className: PropTypes.string,
  inOut: PropTypes.oneOf(['in', 'out']).isRequired,
  type: PropTypes.oneOf(['clickable', 'default', 'none']),
  onClick: PropTypes.func,
  readonly: PropTypes.bool,
};

Endpoint.defaultProps = {
  className: undefined,
  type: 'clickable',
  onClick: undefined,
  readonly: false,
};

export default Endpoint;
