import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@x-functions/freyja/lib/components/heading';
import Column from '../column';
import Level from '../level';
import BaseNode from './baseNode';
import Endpoint from './endpoint';
import Icon from './icon';
import styles from './parallel.module.scss';

const icon = <Icon icon="device_hub" />;

function ParallelNode({
  id,
  name,
  branches,
  renderBranch,
  onOutEndpointClick,
  onFirstNodeEndpointClick,
  readonly,
  ...other
}) {
  return (
    <div className={styles.root}>
      <BaseNode
        id={id}
        readonly={readonly}
        {...other}
        outEndpoint="default"
        image={icon}
        body={(
          <Heading size={7}>{name}</Heading>
        )}
      />
      <Level nowrap>
        {branches.map(b => (
          <Column key={b.id} borderBottom loop>
            {!b.firstNode ? (
              <Endpoint
                type="clickable"
                inOut="out"
                onClick={() => onFirstNodeEndpointClick(id, { branchId: b.id })}
                nodeId={id}
                readonly={readonly}
              />
            ) : (
              renderBranch(b.firstNode, b)
            )}
          </Column>
        ))}
      </Level>
      <Endpoint
        className={styles.loopEndpoint}
        type="clickable"
        inOut="out"
        onClick={onOutEndpointClick}
        nodeId={id}
        readonly={readonly}
      />
    </div>
  );
}

ParallelNode.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  firstNode: PropTypes.string,
  branches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  renderBranch: PropTypes.func.isRequired,
  onOutEndpointClick: PropTypes.func,
  onFirstNodeEndpointClick: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
};

ParallelNode.defaultProps = {
  firstNode: '',
  onOutEndpointClick: undefined,
  readonly: false,
};

export default ParallelNode;
